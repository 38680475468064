
<template>
  <a-layout>
    <a-layout-sider width="300" :style="{background: 'transparent', paddingRight: '12px'}">
      <a-card class="custom-card">
        <a-spin :spinning="treeLoading">
          <div class="scroll-container">
            <a-tree
              :load-data="handleTreeLoad"
              :tree-data="treeData"
              :expandedKeys.sync="expandedKeys"
              :selectedKeys.sync="selectedKeys"
              @select="handleTreeSelect"
            />
          </div>
        </a-spin>
      </a-card>
    </a-layout-sider>
    <a-layout-content>
      <a-card>
        <div class="scroll-container">
          <a-spin :spinning="contentLoading">
            <template v-if="docContent.docTitle">
              <a-breadcrumb>
                <a-breadcrumb-item>{{docContent.parentName}}</a-breadcrumb-item>
                <a-breadcrumb-item>{{docContent.docTitle}}</a-breadcrumb-item>
              </a-breadcrumb>
              <p class="doc-info">
                <span>{{docContent.updateTime}}</span>
                <span>{{docContent.docAuthor}}</span>
              </p>
              <div class="doc-content" v-html="docContent.content"></div>
            </template>
            <no-data v-else />
          </a-spin>
        </div>
      </a-card>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import { helper } from '@/api'

export default {
  name: 'PageHelperIndex',

  setup () {
    const treeData = ref([])
    const treeLoading = ref(false)
    const expandedKeys = ref([])
    const selectedKeys = ref([])
    async function getHelpGroup () {
      treeLoading.value = true
      const { data } = await helper.getHelpGroup()
      treeLoading.value = false
      if (data && data.length) {
        treeData.value = data.map(x => ({
          ...x,
          title: x.className,
          key: x.helpClassId,
          selectable: false
        }))
        expandedKeys.value = [data[0].helpClassId]
      }
    }
    onMounted(() => getHelpGroup())

    function handleTreeLoad (treeNode) {
      const { children, helpClassId, title } = treeNode.dataRef
      return new Promise(async resolve => {
        if (children) {
          resolve()
          return
        }
        const { data } = await helper.getHelpDoc(helpClassId)
        if (data && data.length) {
          treeNode.dataRef.children = data.map(x => ({
            ...x,
            title: x.docTitle,
            key: x.helpDocId,
            isLeaf: true
          }))
          treeData.value = [...treeData.value]
          if (!docContent.value.docTitle) {
            selectedKeys.value = [data[0].helpDocId]
            handleTreeSelect([data[0].helpDocId], title)
          }
        }
        resolve()
      })
    }

    const contentLoading = ref(false)
    const docContent = ref({
      docTitle: '',
      docAuthor: '',
      content: '',
      updateTime: ''
    })
    async function handleTreeSelect (selectedKeys, e) {
      contentLoading.value = true
      const { data } = await helper.getHelpDocDetail(selectedKeys[0])
      contentLoading.value = false
      if (data) {
        docContent.value = {
          parentName: typeof e === 'string' ? e : e.node.$parent.dataRef.className,
          ...data
        }
      }
    }

    return {
      treeData,
      treeLoading,
      expandedKeys,
      selectedKeys,
      handleTreeLoad,
      contentLoading,
      docContent,
      handleTreeSelect
    }
  }
}
</script>

<style lang="less" scoped>
.scroll-container {
  max-height: calc(100vh - 186px);
  overflow-y: auto;
}
.doc-info {
  padding: 12px 0;
  font-size: 13px;
  color: #999;
  span {
    margin-right: 12px;
  }
}
.doc-content {
  ::v-deep img {
    max-width: 100%;
  }
}
</style>
